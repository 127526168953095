import "./BaseLayout.scss";

import { ReactNode, useEffect } from "react";
import cx from "classnames";
import { twMerge } from "tailwind-merge";
import { useLocation } from "react-router-dom";
import { datadogRum } from "@datadog/browser-rum";
import { monitor } from "@/utils/monitoring.service";

export interface iBaseLayout {
  children: ReactNode;
  containerClass?: string;
  withPadding?: boolean;
}

export const BaseLayout = ({ children, containerClass, withPadding = true }: iBaseLayout) => {
  const location = useLocation();
  const path = location.pathname;
  const classes = twMerge(`layout w-full bg-brand-tertiary`, containerClass);

  useEffect(() => {
    datadogRum.startView(location.pathname);

    if (location.pathname !== "/") {
      monitor.trackView(location.pathname);
    }
  }, []);

  return (
    <div className={cx(classes)}>
      <div
        className={cx("mx-auto flex flex-col", {
          "lg:container": path !== "/measurement/do",
          "measurement-result-bg": path.includes("/measurement/result"),
        })}
      >
        <div
          className={cx(
            // min-h-screen is a fallback for the 'min-height' defined for the 'base-layout' class, see css file
            "min-h-screen base-layout flex flex-col sm:layout relative w-full bg-brand-tertiary lg:my-auto min-w-[280px]",
            withPadding && path !== "/measurement/do" ? "px-4 md:px-[25px]" : "",
            {
              "result-page": path.includes("/measurement/result"),
            },
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
};
