import { IntelliProveService } from "@/services/intelliprove.service";
import React, { useContext, createContext } from "react";

const SDKContext = createContext<IntelliProveService | null>(null);
interface SDKProviderProps {
  sdk: IntelliProveService;
  children: React.ReactNode; // ReactNode allows any valid JSX child
}

export const SDKProvider: React.FC<SDKProviderProps> = ({ sdk, children }) => {
  return <SDKContext.Provider value={sdk}>{children}</SDKContext.Provider>;
};

export const useSDK = (): IntelliProveService => {
  const context = useContext(SDKContext);
  if (!context) {
    throw new Error("'useSDK' must be used within an SDKProvider");
  }
  return context;
};
