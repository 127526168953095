import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { shallowEqual } from "react-redux";

import { Splashscreen } from "./pages/Splashscreen/Splashscreen";

import "@/translations/i18next";

import { useAppDispatch, useAppSelector } from "./hooks";
import { useEffect, useState } from "react";
import { ModalProvider } from "./utils/modal.provider";
import { AppModal, iAppModal } from "./components/Modal/Modal";
import { useTranslation } from "react-i18next";
import { IntelliProveService } from "./services/intelliprove.service";
import { useTheme } from "./context/theme";
import { Cookie, useLanguage } from "./context/language";
import { ScreenSizeTooSmallModal } from "./components/Modal/ModalScreenSize";
import { customerFromJwt, debugLog, getSearchParam, validSearchParam, genThemeObject } from "./utils/helper";
import { AuthenticationMethod, PluginSettings, QuestionItem } from "intelliprove-streaming-sdk";
import { useCookies } from "react-cookie";
import { monitor } from "./utils/monitoring.service";
import { parseInt } from "lodash";
import { SDKProvider } from "./providers/Sdk.provider";

import {
  setAuth,
  setDuration,
  setLanguage,
  setMetadata,
  setOnboarding,
  setPluginSetting,
  setQuestionnaireQuestions,
} from "./store/reducers/setting.reducer";

export const AppBootstrap = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { changeLanguage } = useLanguage();
  const [setting] = useAppSelector((state) => [state.setting], shallowEqual);
  const [cookies, _] = useCookies([Cookie.Language]);

  const [error, setError] = useState<iAppModal | undefined>(undefined);
  const [sdk, setSDK] = useState<IntelliProveService | null>(null);
  const [sdkReady, setSDKReady] = useState<boolean>(false);

  const __handleNoAuth = () => {
    theme.applyTheme({});
    setError({
      isOpen: true,
      title: t("access_not_granted_title", "Access not granted"),
      text: t(
        "access_not_granted",
        "To use the health check, you must have the access key. If you don't have it, please get in touch with your responsible team to request access.",
      ),
      dismissable: false,
      icon: "circle_x",
    });
  };

  const __setMetadata = () => {
    const patient = getSearchParam("patient");
    const performer = getSearchParam("performer");
    const duration = getSearchParam("duration");

    const onboarding = !Boolean(localStorage.getItem("onboarding"));
    dispatch(setOnboarding(onboarding));

    const userId = monitor.metadata.userId;
    const sessionId = monitor.metadata.sessionId;

    dispatch(setMetadata({ patient, performer, userId, sessionId }));
    dispatch(setDuration(!duration || isNaN(Number(duration)) ? 20000 : Number(duration) * 1000));

    monitor.trackDeviceInfo();
  };

  const __handleSettingsLoad = (settings: PluginSettings) => {
    const isDefaultTheme = validSearchParam("default");

    const mode = getSearchParam("mode");
    const skipWelcome = getSearchParam("no_welcome");
    const skipResults = getSearchParam("no_results");

    const theming = isDefaultTheme ? {} : genThemeObject(settings.theming) ?? {};

    settings.skip_welcome = skipWelcome != null ? ["1", "true"].includes(skipWelcome) : settings.skip_welcome;
    settings.skip_results = skipResults != null ? ["1", "true"].includes(skipResults) : settings.skip_results;
    settings.embedded_iframe = mode != null ? mode === "iframe" : settings.embedded_iframe;
    settings.embedded_app = mode != null ? mode === "app" : settings.embedded_app;
    settings.theming = theming;

    if (settings.theming["dark_mode"] === true) {
      document.body.classList.add("dark");
    }

    theme.applyTheme(settings.theming);
    dispatch(setPluginSetting(settings));
  };

  const __loadQuestionDefinitions = async () => {
    if (sdk === null) {
      throw new ReferenceError("SDK reference in App state is not set correctly!");
    }

    const questionIdsJoined = getSearchParam("q_ids");

    let questionDefs: QuestionItem[] = [];
    const questionIds = (questionIdsJoined ? questionIdsJoined.split(",") : []).map((id) => parseInt(id));
    if (sdk.instance && questionIds.length > 0) {
      questionDefs = await sdk.instance.getQuestions(questionIds);
    }

    dispatch(setQuestionnaireQuestions(questionDefs));
  };

  // On app state change
  useEffect(() => {
    if (sdk === null) {
      return;
    }

    // plugin settings
    sdk.instance
      ?.getPluginSettings()
      .then((settings) => {
        if (!settings) {
          console.warn("getPluginSettings returning undefined?");
          return;
        }

        debugLog("Plugin settings loaded");
        __handleSettingsLoad(settings);
      })
      .catch((err) => {
        console.error("Failed to get plugin settings");
        throw err;
      });

    __loadQuestionDefinitions()
      .then(() => {
        debugLog("Questions loaded");
      })
      .catch((err) => {
        console.error("Failed to load question definitions!");
        throw err;
      });

    // language
    const language = getSearchParam("language") ?? cookies.language ?? "en";
    changeLanguage(sdk, language);
    dispatch(setLanguage(language));
  }, [sdk]);

  useEffect(() => {
    if (setting.pluginSettings && location.pathname === "/") {
      if (setting.pluginSettings.skip_welcome) {
        navigate(setting.onboarding ? "/measurement/onboarding" : "/measurement/do", { replace: true, state: { from: location.pathname } });
      } else {
        navigate("/welcome", { replace: true, state: { from: location.pathname } });
      }
    }
  }, [setting]);

  // On AppBootstrap init
  useEffect(() => {
    if (setting.actionToken) return;

    const authToken = getSearchParam("action_token") ?? getSearchParam("auth0");

    if (!authToken) {
      __handleNoAuth();
      return;
    }

    monitor.setCustomer(customerFromJwt(authToken));
    monitor.trackView("/");

    try {
      const authenticationMethod = !getSearchParam("auth0") ? AuthenticationMethod.ActionToken : AuthenticationMethod.Auth0;
      const sdk = new IntelliProveService(authToken, authenticationMethod);
      setSDK(sdk);

      debugLog(`AppBootstrap: got action_token, checking token validity`);
      sdk.isAuthenticated().then((authenticated: boolean) => {
        dispatch(setAuth([authToken, authenticationMethod]));
        if (authenticated) {
          setSDKReady(true);
        } else {
          __handleNoAuth();
        }
      });

      __setMetadata();
    } catch (e) {
      monitor.trackError(e);
      debugLog(`ERROR:`, e);
      theme.applyTheme({});
      setError({
        isOpen: true,
        title: t("access_not_granted_title", "Access not granted"),
        text: t("access_not_granted_invalid_expired_key"),
        dismissable: false,
        icon: "circle_key",
      });
    }
  }, []);

  return (
    <ModalProvider>
      {sdkReady && sdk !== null ? (
        <SDKProvider sdk={sdk!}>
          <Outlet />
        </SDKProvider>
      ) : (
        <Splashscreen />
      )}

      <ScreenSizeTooSmallModal />

      <AppModal
        isOpen={error?.isOpen}
        title={error?.title}
        text={error?.text}
        onDismiss={() => error?.onDismiss?.()}
        dismissable={error?.dismissable}
        icon={error?.icon}
        action={error?.action}
        secondaryAction={error?.secondaryAction}
      />
    </ModalProvider>
  );
};
