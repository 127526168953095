import { useTranslation } from "react-i18next";
import cx from "classnames";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import { BiomarkerArcCard } from "./BiomarkerArcCard/BiomarkerArcCard";
import { BiomarkerLinearCard } from "./BiomarkerLinearCard/BiomarkerLinearCard";
import { BiomarkerEmptyCard } from "./BiomarkerEmptyCard/BiomarkerEmptyCard";
import { BiomarkerZone, BucketBiomarker } from "intelliprove-streaming-sdk";
import { ResultBiomarkerSkeletonCard } from "./ResultBiomarkerSkeletonCard";
import { isEmpty } from "@/utils/helper";
import { IconInfo } from "../Icons/icon_info";
import { useTheme } from "@/context/theme";
import { IconClose } from "../Icons";
import { IconFromName } from "../AssetsManager/AssetsManager";
import Typography from "../Typography/Typography";

export const ResultBiomarkerCard = ({
  biomarker,
  showDetail,
  setShowDetail,
}: {
  biomarker?: BucketBiomarker;
  showDetail: string;
  setShowDetail: (value: string) => void;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const __renderGraph = () => {
    if (!biomarker) {
      return <ResultBiomarkerSkeletonCard />;
    }

    // if (biomarker.name === 'mental_health_risk') {
    //   // @ts-ignore
    //   return <BiomarkerArcCard biomarker={{ ...biomarker, value: 2, zone: 'good' }} />;
    // }

    if (isEmpty(biomarker.value)) {
      return <BiomarkerEmptyCard biomarker={biomarker} />;
    }

    if (
      [
        "acute_mental_stress_score",
        "mental_stress",
        "morning_readiness",
        "sleep_quality",
        "ans_balance",
        "energy_balance",
        "mental_health_risk",
      ].includes(biomarker.name)
    ) {
      return <BiomarkerArcCard biomarker={biomarker} />;
    }

    return <BiomarkerLinearCard biomarker={biomarker} />;
  };

  const ToolTipIcon = (props: any) => {
    if (showDetail === biomarker?.name) {
      return <IconClose {...props} />;
    }

    return <IconInfo {...props} />;
  };

  const __backgroundColor = () => {
    if (!biomarker) {
      return "var(--color-brand-tertiary)";
    }

    return isNaN(parseInt(biomarker?.value))
      ? theme.value.dark_mode
        ? "var(--rgb-brand-primary)"
        : "rgba(var(--rgb-support-300), 0.5)"
      : "var(--color-brand-tertiary)";
  };

  // Mapping results to 2.0
  if (biomarker?.name == "acute_mental_stress_score") {
    biomarker.name = "mental_stress";
    biomarker.value = biomarker.value ? `${5 - +biomarker.value}` : biomarker.value;
  } else if (biomarker?.name == "morning_readiness") {
    biomarker.name = "sleep_quality";
    biomarker.value = biomarker.value ? `${+biomarker.value * 10}` : biomarker.value;
    biomarker.range = [0, 100];
  } else if (biomarker?.name == "ans_balance") {
    biomarker.name = "energy_balance";
  }

  return (
    <SkeletonTheme baseColor={theme.value.support_100} highlightColor={theme.value.brand_tertiary}>
      <div
        className={cx(
          "relative flex flex-col w-full justify-between flex-[100%] rounded-[20px] border-secondary shadow-md py-[4vw] px-[14px] border-[1px] sm:p-[24px] sm:max-h-[216px] transition-all duration-400",
          {
            "border-functional-secondary-500 border-[1px]": biomarker?.zone === "neutral",
            "border-functional-primary-500 border-[1px]": biomarker?.zone === "bad",
            hidden: showDetail && showDetail !== biomarker?.name,
            // "bg-white": !biomarker || (biomarker && !isEmpty(biomarker.value)),
            // "bg-secondary": biomarker && isEmpty(biomarker.value),
          },
          showDetail && showDetail === biomarker?.name ? "sm:max-w-[100%]" : "sm:max-w-[216px] aspect-square",
        )}
        style={{
          backgroundColor: __backgroundColor(),
        }}
      >
        <div className="absolute top-[10px] right-[10px]">
          {!!biomarker && (
            <ToolTipIcon
              className={cx("cursor-pointer w-8 h-8 p-1 pl-2", { "p-2": showDetail === biomarker?.name })}
              onClick={() => setShowDetail(showDetail === biomarker?.name ? "" : biomarker?.name || "")}
            />
          )}
        </div>

        <div className={cx(biomarker?.name === showDetail ? "flex flex-col sm:flex-row items-center" : "")}>
          {showDetail && showDetail === biomarker?.name && (
            <div className="text-center flex flex-col sm:hidden">
              <Typography.Text className="inline text-[16px]">
                {t(biomarker?.name || "")}
                &nbsp;
                {t(biomarker?.name || "").length > 35 && <br />}
                <span className="inline">
                  <IconFromName name={biomarker?.name || ""} className="w-[7vw] inline" />
                  &nbsp;
                  <span
                    className={cx("text-[21px] font-bold", {
                      "text-functional-primary-500": biomarker?.zone === "bad",
                      "text-functional-secondary-500": biomarker?.zone === "neutral",
                      "text-brand-secondary": !["bad", "neutral"].includes(biomarker?.zone || ""),
                    })}
                  >
                    {biomarker?.value}
                  </span>
                  &nbsp;
                  <span className="text-support-500 text-[15px]">{biomarker?.unit}</span>
                </span>
              </Typography.Text>
              <div className="block sm:hidden text-[16px]">
                {biomarker?.value === null && t(`${biomarker?.name || ""}_null_description`, "No data measured")}
              </div>
            </div>
          )}

          <div
            className={cx(
              "flex-col aspect-square justify-between",
              biomarker?.name === showDetail ? "hidden sm:flex min-w-[166px] h-[168px] sm:ml-12" : "flex sm:h-full w-full sm:w-[unset]",
            )}
          >
            <div
              className={cx(
                "text-primary text-support-500 font-semibold text-center h-[35px] px-4 sm:px-2 lg:px-0 sm:h-[45px]",
                biomarker && t(biomarker.name).length > 28 ? "text-[3vw] sm:text-base" : "text-[3vw] sm:text-lg",
              )}
              style={{ wordBreak: "break-word" }}
            >
              {biomarker ? t(biomarker.name) : <Skeleton className="w-[80px] h-[10px] sm:h-[20px]" />}
            </div>

            {__renderGraph()}
          </div>
          <div
            className={cx("flex mt-2 max-h-[150px] overflow-y-auto text-primary text-support-500 w-full mx-12 sm:my-auto", {
              hidden: biomarker?.name !== showDetail,
            })}
          >
            {biomarker ? t(`${biomarker?.name}_definition_text`) : ""}
          </div>
        </div>
      </div>
    </SkeletonTheme>
  );
};
