import { IMappedTheme } from "@/context/theme";
import { monitor } from "./monitoring.service";

export const isEmpty = (value: any): boolean => {
  return value === undefined || value === null;
};

export const hexToRgb = (hex: string): string => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  if (result) {
    return [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)].join(",");
  }

  return "";
};

export const wait = async (duration: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(resolve, duration);
  });
};

export const debugLog = (...args: any[]) => {
  if (process.env.NODE_ENV !== "production") {
    console.log(...args);
  }
};

export const mapErrorCode = (errorCode: number) => {
  let translationKey = "error_oops";
  switch (errorCode) {
    case 3:
      translationKey = "error_no_face";
      break;
    case 4:
      translationKey = "error_insufficient_skin";
      break;
    case 5:
      translationKey = "error_out_of_frame";
      break;

    // Front-end
    case 1001:
      translationKey = "error_connection_lost";
      break;
    case 1002:
      translationKey = "error_insufficient_fps";
      break;
    default:
      break;
  }

  return {
    title: translationKey,
    text: `${translationKey}_description`,
    live: `${translationKey}_live`,
  };
};

export const mapQualityErrorCode = (errorCode: number) => {
  let transationKey = "qc_generic_message";
  switch (errorCode) {
    case 1:
      transationKey = "qc_too_close";
      break;
    case 2:
      transationKey = "qc_too_far";
      break;
    case 3:
      transationKey = "qc_align_and_center";
      break;
    case 4:
      transationKey = "qc_chest_not_visible";
      break;
    case 5:
      transationKey = "qc_bad_pose";
      break;
    case 6:
      transationKey = "qc_too_dark";
      break;
    case 7:
      transationKey = "qc_forehead_out_of_frame";
      break;
    case 8:
      transationKey = "qc_no_face";
      break;
    case 9:
      transationKey = "qc_insufficient_skin_area";
      break;
    default:
      break;
  }

  return {
    title: transationKey,
    text: `${transationKey}_description`,
    live: `${transationKey}_live`,
  };
};

export const customerFromJwt = (token: string) => {
  try {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(""),
    );

    let body = JSON.parse(jsonPayload);
    return body.user?.customer /*ACTION TOKEN*/ ?? body[body.aud + "/app"]?.customer;
  } catch {
    return null;
  }
};

export const openInNewTab = (url: string): void => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

export const getSearchParam = (key: string): string | null => {
  const params = new URLSearchParams(window.location.search);
  const val = params.get(key)?.replaceAll(" ", "+");
  return val || null;
};

export const validSearchParam = (key: string): boolean => {
  const val = getSearchParam(key);
  if (!val) return false;
  try {
    if (JSON.parse(val)) return true;
  } catch (e) {
    monitor.trackError(e);
    debugLog(`'${key}' search param exists, but JSON.parse failed. Value -> ${val}`);
  }
  return false;
};

export const genThemeObject = (theming: IMappedTheme): object | null => {
  return typeof theming === "object" ? theming : null;
};
