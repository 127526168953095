export function scrollToElementHorizontal(elem: HTMLElement, duration: number) {
  if (!elem.parentNode) throw new Error("Cannot scroll for element, element has no parent element!");

  const parent = elem.parentNode as HTMLElement;
  const parentPos = parent.getBoundingClientRect();
  const elemPos = elem.getBoundingClientRect();

  const middle = parentPos.width / 2 + parentPos.left;
  const offset = elemPos.x + elemPos.width / 2 - middle;

  parent.scrollBy({ left: offset, behavior: "smooth" });
}
