import { MouseEventHandler, ReactNode } from "react";
import cx from "classnames";
import { twMerge } from "tailwind-merge";

import "./Button.scss";

export interface iButton {
  prefix?: ReactNode;
  suffix?: ReactNode;
  text?: string | ReactNode;
  type?: ButtonType;
  onClick?: MouseEventHandler<HTMLDivElement>;
  disabled?: boolean;
  textStyles?: React.CSSProperties;
  content?: ReactNode;
  containerClass?: string;
  className?: string;
}

export enum ButtonType {
  Primary,
  Secondary,
  Accent,
  Outline,
  Dark,
  White,
}

export const Button = ({
  content,
  prefix,
  suffix,
  text = "",
  onClick,
  type = ButtonType.Secondary,
  disabled = false,
  textStyles = {},
  containerClass,
  className,
}: iButton) => {
  const __prefix = () => {
    if (!prefix) {
      return null;
    }

    return <span className="sm:mr-2">{prefix}</span>;
  };

  const __suffix = () => {
    if (!suffix) return null;
    return <span className="ml-2">{suffix}</span>;
  };

  const __buttonTextCssClass = () => {
    switch (type) {
      case ButtonType.Accent:
        return "text-brand-tertiary";
      case ButtonType.Dark:
        return "text-brand-tertiary dark:text-brand-secondary";
      case ButtonType.White:
        return "text-brand-secondary dark:text-brand-tertiary";
      case ButtonType.Secondary:
        return "text-brand-secondary dark:text-brand-tertiary";
      default:
        return "text-brand-secondary dark:text-brand-tertiary";
    }
  };

  const __buttonBackgroundCssClass = () => {
    switch (type) {
      case ButtonType.Accent:
        return "bg-brand-primary";
      case ButtonType.Primary:
        return "bg-brand-accent";
      case ButtonType.Secondary:
        return "bg-support-300";
      case ButtonType.Dark:
        return "bg-brand-secondary-64 dark:bg-brand-tertiary-64";
      case ButtonType.White:
        return "bg-white";
      default:
        return "";
    }
  };

  return (
    <div
      className={cx(
        // 'bucket-button' is class for JS, see __renderButtons in Result.tsx
        twMerge(
          `bucket-button flex flex-row justify-center button-container min-h-[40px] text-primary px-[16px] ${className} items-center`,
        ),
        __buttonTextCssClass(),
        __buttonBackgroundCssClass(),
        {
          "opacity-50": disabled,
          "cursor-pointer": !disabled,
        },
        containerClass,
      )}
      onClick={onClick}
    >
      {__prefix()}
      {content}
      <span className={cx("text-md", { underline: type === ButtonType.Outline })} style={textStyles}>
        {text}
      </span>
      {__suffix()}
    </div>
  );
};
