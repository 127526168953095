import { JsonObject } from "../interfaces";

import UserInfo from "../models/user_info";
import StreamStatus from "../models/stream_status";
import PluginSettings from "../models/plugin_settings";
import BucketsResponse from "../models/buckets_response";
import QualityResponse from "../models/quality_response";
import BiomarkersResponse from "../models/biomarkers_response";
import ResultsCountResponse from "../models/results_count_response";
import BucketFeedbackResponse from "../models/bucket_feedback_response";
import UnprocessableVideoResponse from "../models/unprocessable_video_response";

import IntelliProveAPIError from "../exceptions/intelliprove_api_exception";
import PluginTranslations from "../models/plugin_translations";
import StreamingMetadata from "../models/streaming_metadata";
import { QuestionItem } from "../models/questions_response";
import { QuestionAnswerRequest } from "../models/question_answer_request";

export enum AuthenticationMethod {
  ActionToken = "actiontoken",
  Auth0 = "auth0",
}

export default class IntelliProveAPI {
  apiurl: string;
  authentication: string;
  authenticationMethod: AuthenticationMethod;

  constructor(apiurl: string, authentication: string, authenticationMethod: AuthenticationMethod = AuthenticationMethod.ActionToken) {
    this.apiurl = apiurl;
    this.authentication = authentication;
    this.authenticationMethod = authenticationMethod;
  }

  makeUri(suburi: string, queryParams: object = {}) {
    if (suburi.includes("http")) return suburi;
    let params: Array<string> = [];
    Object.entries(queryParams).forEach(([key, value]) => {
	  // Our API requires multiple values like this
	  // http://url/route?key=val1&key=val2
	  if (Array.isArray(value)) {
		value.forEach(v => params.push(`${key}=${v}`));
	  } else {
		params.push(`${key}=${value}`);
	  }
    });

    const queryString = "?" + params.join("&");
    return Object.keys(queryParams).length > 0 ? this.apiurl + suburi + queryString : this.apiurl + suburi;
  }

  headers(contentType: string | null = null) {
    let h: JsonObject = {};

    if (this.authenticationMethod == AuthenticationMethod.ActionToken) {
      h["authorization"] = `Token ${this.authentication}`;
    } else if ((this.authenticationMethod = AuthenticationMethod.Auth0)) {
      h["authorization"] = `Bearer ${this.authentication}`;
    }

    if (contentType !== null) {
      h["Content-Type"] = contentType;
    }

    return h;
  }

  post(uri: string, body: any | null = null, queryParams = {}, contentType: string | null = null): Promise<Response> {
    const url = this.makeUri(uri, queryParams);
    return fetch(url, {
      method: "POST",
      body: body,
      headers: this.headers(contentType),
    });
  }

  get(uri: string, queryParams = {}): Promise<Response> {
    const url = this.makeUri(uri, queryParams);
    return fetch(url, {
      method: "GET",
      headers: this.headers(),
    });
  }

  delete(uri: string, queryParams = {}): Promise<Response> {
    const url = this.makeUri(uri, queryParams);
    return fetch(url, {
      method: "DELETE",
      headers: this.headers(),
    });
  }

  async handleError(response: Response): Promise<null> {
	if (response.status === 0) {
	  // Request cancelled by external factor
	  return null
	}
    if (response.status === 401) {
      throw new IntelliProveAPIError("Invalid authentication token.", response.status);
    }
    if (response.status === 403) {
      throw new IntelliProveAPIError("Forbidden, you do not have access to this resource.", response.status);
    }
    if (response.status === 404) {
      return null;
    }
    if (response.status === 422 || response.status === 406) {
      const body: any = await response.json();
      throw new IntelliProveAPIError(body.detail[0].msg, response.status);
    }
    if (response.status < 500) {
      try {
        const body: any = await response.json();
        console.error(body);
      } catch (err) {}
      throw new IntelliProveAPIError("Unknown API error", response.status);
    }

    throw new IntelliProveAPIError("Something went wrong while trying to access the IntelliProve API.", response.status);
  }

  // streaming

  async getStreamingEndpoint() {
    const uri = "/v1/streaming/ws-endpoint";
    const response = await this.get(uri);
    if (response.status === 200) {
      return JSON.parse(await response.text());
    }
    throw new IntelliProveAPIError("Unable to fetch websocket endpoint from API!", response.status);
  }

  async createStream(metadata: StreamingMetadata, timezone: string | null = null): Promise<StreamStatus> {
    let uri = "/v1/streaming/new";

    const queryParams = new URLSearchParams();
    if (metadata.performer !== null) queryParams.set("performer-ref", metadata.performer);
    if (metadata.patient !== null) queryParams.set("patient-ref", metadata.patient);
    if (timezone !== null) queryParams.set("timezone", timezone);
    if (metadata.userId !== null) queryParams.set("usr", metadata.userId.toString());
    if (metadata.sessionId !== null) queryParams.set("sess", metadata.sessionId.toString());

    uri += "?" + queryParams.toString();

    const response = await this.post(uri);
    if (response.status === 201) {
      return new StreamStatus(await response.json());
    }

    await this.handleError(response);
    throw new IntelliProveAPIError("Failed to create new stream, unknown error!", response.status);
  }

  // general

  async qualityCheck(image: Blob, metadata: StreamingMetadata | null = null): Promise<QualityResponse> {
    let uri = "/v1/videos/check";

    const queryParams = new URLSearchParams();
    if (metadata !== null && metadata.userId !== null) queryParams.set("usr", metadata.userId.toString());
    if (metadata !== null && metadata.sessionId !== null) queryParams.set("sess", metadata.sessionId.toString());

    uri += "?" + queryParams.toString();

    const formData = new FormData();
    formData.append("image", image);
    const response = await this.post(uri, formData);
    if (response.status === 200) {
      return new QualityResponse(await response.json());
    }

    await this.handleError(response);
    throw new IntelliProveAPIError("Failed to do quality check!", response.status);
  }

  async getResultsCount(
    from: Date | null = null,
    until: Date | null = null,
    performer: string | null = null,
    patient: string | null = null,
  ): Promise<ResultsCountResponse> {
    let uri = '/v1/results/count';
    let queryParams = new URLSearchParams();

    if (from !== null) queryParams.set("from", from.toISOString());
    if (until !== null) queryParams.set("from", until.toISOString());
    if (performer !== null) queryParams.set("performer-ref", performer);
    if (patient !== null) queryParams.set("patient-ref", patient);

    if (queryParams.toString().length > 0) {
      uri += "?" + queryParams.toString();
    }

    const response = await this.get(uri);

    if (response.status === 200) {
      return new ResultsCountResponse(await response.json());
    }

    return await this.handleError(response);
  }

  async getResults(uuid: string): Promise<BiomarkersResponse | UnprocessableVideoResponse | null> {
    let uri = `/v1/results/wait/${uuid}`;

    const response = await this.get(uri);
    if (response.status === 200) {
      return new BiomarkersResponse(await response.json());
    } else if (response.status === 202) {
      return null;
    } else if (response.status === 422) {
      // Validation error
      return new UnprocessableVideoResponse(await response.json());
    }
    return await this.handleError(response);
  }

  async getBuckets(uuid: string): Promise<BucketsResponse | null> {
    let uri = `/v1/buckets/all/${uuid}`;

    const response = await this.get(uri);
    if (response.status >= 200 && response.status < 299) {
      return new BucketsResponse(await response.json());
    } else if (response.status === 400) {
      return null;
    } else {
      return await this.handleError(response);
    }
  }

  async getBucketFeedback(uuid: string, language: string = "en", includeTip: boolean = false): Promise<BucketFeedbackResponse | null> {
    let uri = `/v1/buckets/summary/${uuid}?include_tips=${includeTip}&language=${language}`;

    const response = await this.get(uri);
    if (response.status >= 200 && response.status < 299) {
      return new BucketFeedbackResponse(await response.json());
    }

    return await this.handleError(response);
  }

  async getUser(): Promise<UserInfo | null> {
    let uri = "/v1/auth/me";
    const response = await this.get(uri);
    if (response.status >= 200 && response.status < 299) {
      return new UserInfo(await response.json());
    }

    return await this.handleError(response);
  }

  async getPluginSettings(): Promise<PluginSettings | null> {
    let uri = `/v1/plugin/settings`;
    const response = await this.get(uri);
    if (response.status >= 200 && response.status < 299) {
      return new PluginSettings(await response.json());
    }
    return await this.handleError(response);
  }

  async getQuestions(ids: Array<number>): Promise<Array<QuestionItem> | null> {
	let uri = '/v2/questions';
	const queryParams = {'ids': ids};
	const response = await this.get(uri, queryParams);
    if (response.status >= 200 && response.status < 299) {
	  return await response.json() as Array<QuestionItem>;
	}
	return await this.handleError(response);
  }

  async answerQuestion(question_id: number, choosen_answer: QuestionAnswerRequest): Promise<boolean> {
  	let uri = `/v2/questions/${question_id}/answer`;
	const body = JSON.stringify(choosen_answer);
	const response = await this.post(uri, body, {}, 'application/json');
    return response.status >= 200 && response.status < 299;
  }

  async getPluginTranslations(locale: string): Promise<PluginTranslations | null> {
    let uri = `/v1/plugin/translations/${locale}`;
    const response = await this.get(uri);
    if (response.status === 200) {
      return new PluginTranslations(locale, await response.json());
    }
    return await this.handleError(response);
  }
}
