import { isMobile } from "react-device-detect";

// Enum for browser types
export enum BrowserType {
  MicrosoftEdge = "Microsoft Edge",
  GoogleChrome = "Google Chrome",
  Safari = "Safari",
  MozillaFirefox = "Mozilla Firefox",
  iOSWebView = "iOS WebView",
  AndroidWebView = "Android WebView",
  Unknown = "Unknown Browser",
}

export function getBrowserType(): BrowserType {
  const userAgent: string = navigator.userAgent;
  const vendor: string = navigator.vendor || ""; // Vendor string helps distinguish some browsers
  const platform: string = navigator.platform || ""; // Platform helps identify iOS-specific WebView

  // Microsoft Edge (Chromium-based)
  if (userAgent.includes("Edg")) {
    return BrowserType.MicrosoftEdge;

    // Google Chrome (exclude Edge, Opera, and Chromium-based WebView)
  } else if (userAgent.includes("Chrome") && vendor.includes("Google") && !userAgent.includes("Edg") && !userAgent.includes("OPR")) {
    return BrowserType.GoogleChrome;

    // Safari (on macOS/iOS only, excludes Chrome-based browsers)
  } else if (userAgent.includes("Safari") && !userAgent.includes("Chrome") && vendor.includes("Apple")) {
    return BrowserType.Safari;

    // Mozilla Firefox
  } else if (userAgent.includes("Firefox") || userAgent.includes("FxiOS")) {
    return BrowserType.MozillaFirefox;

    // iOS WebView (standalone web apps on iOS)
  } else if (/CriOS|FxiOS|EdgiOS/.test(userAgent) && platform.includes("iPhone")) {
    return BrowserType.iOSWebView;

    // Android WebView
  } else if (userAgent.includes("wv") || (userAgent.includes("Android") && userAgent.includes("Version"))) {
    return BrowserType.AndroidWebView;

    // Fallback for unknown browsers
  } else {
    return BrowserType.Unknown;
  }
}

export function getBrowserCameraHintUrl(): string {
  const browser = getBrowserType();
  let url = "https://docs.intelliprove.com/users/enabling-camera-access-for-the-intelliprove-plug-in";

  const urls: Record<BrowserType, string> = {
    "Google Chrome": "https://docs.intelliprove.com/users/enabling-camera-access-for-the-intelliprove-plug-in#7i6uo",
    "Mozilla Firefox": "https://docs.intelliprove.com/users/enabling-camera-access-for-the-intelliprove-plug-in#H6Dxk",
    Safari: "https://docs.intelliprove.com/users/enabling-camera-access-for-the-intelliprove-plug-in#AbJSP",
    "Microsoft Edge": "https://docs.intelliprove.com/users/enabling-camera-access-for-the-intelliprove-plug-in#CGn52",

    "iOS WebView": "https://docs.intelliprove.com/users/enabling-camera-access-for-the-intelliprove-plug-in#1szRH",
    "Android WebView": "https://docs.intelliprove.com/users/enabling-camera-access-for-the-intelliprove-plug-in#ltqRS",
    "Unknown Browser": url,
  };
  if (browser in urls) {
    url = urls[browser];
  }

  if (browser == BrowserType.Safari && isMobile) url = urls[BrowserType.iOSWebView];
  if (browser == BrowserType.Unknown && isMobile) url = urls[BrowserType.AndroidWebView];

  return url;
}
