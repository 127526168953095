import { useTheme } from "@/context/theme";
import { SVGProps } from "react";

export const IconCameraDenied = (props: SVGProps<SVGSVGElement>) => {
  const theme = useTheme();

  return (
    <svg width="70" height="89" viewBox="0 0 70 89" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group 108">
        <path
          id="Union"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M69.4864 34.9865C69.4864 47.9059 62.3823 59.166 51.8674 65.0736C51.965 65.1278 52.0511 65.2056 52.1163 65.3045L66.6765 87.394C66.9892 87.8684 66.6489 88.5001 66.0807 88.5001H3.40681C2.83414 88.5001 2.49481 87.8595 2.81657 87.3857L17.8223 65.2924C17.8938 65.1871 17.9892 65.1064 18.0967 65.0534C7.60144 59.1404 0.513428 47.8912 0.513428 34.9865C0.513428 15.9401 15.9536 0.5 34.9999 0.5C54.0463 0.5 69.4864 15.9401 69.4864 34.9865ZM34.9999 69.473C40.0827 69.473 44.9087 68.3734 49.253 66.3991C49.2352 66.561 49.2724 66.7304 49.3723 66.8774L61.4153 84.5909C61.7374 85.0646 61.3981 85.7056 60.8253 85.7056H9.29296C8.72036 85.7056 8.38101 85.065 8.70264 84.5913L20.6883 66.9367C20.7951 66.7793 20.8301 66.5964 20.8027 66.4244C25.1325 68.3828 29.9389 69.473 34.9999 69.473ZM3.96208 34.9865C3.96208 52.1282 17.8582 66.0243 34.9999 66.0243C52.1416 66.0243 66.0378 52.1282 66.0378 34.9865C66.0378 17.8448 52.1416 3.94865 34.9999 3.94865C17.8582 3.94865 3.96208 17.8448 3.96208 34.9865ZM35.0002 51.0407C43.7353 51.0407 50.8164 43.8797 50.8164 35.0461C50.8164 26.2125 43.7353 19.0515 35.0002 19.0515C26.2652 19.0515 19.184 26.2125 19.184 35.0461C19.184 43.8797 26.2652 51.0407 35.0002 51.0407ZM35.0002 47.8221C28.0229 47.8221 22.3667 42.1021 22.3667 35.0461C22.3667 27.9901 28.0229 22.2701 35.0002 22.2701C41.9775 22.2701 47.6337 27.9901 47.6337 35.0461C47.6337 42.1021 41.9775 47.8221 35.0002 47.8221Z"
          fill={theme.value.support_500}
        />
        <path
          id="Vector 2"
          d="M27.5083 43.1918L42.7299 27.9702M42.7299 43.1918L27.5083 27.9702"
          stroke={theme.value.support_500}
          strokeWidth="2.37838"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
