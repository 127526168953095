import "./Modal.scss";
import Modal from "react-modal";
import { Trans, useTranslation } from "react-i18next";
import cx from "classnames";
import { IconCircleExclamation } from "../Icons/icon_circle_exclamation";
import { Button, ButtonType } from "../Button/Button";
import { IconCircleX } from "../Icons/icon_circle_x";
import { IconCircleKey } from "../Icons/icon_circle_key";
import { IconNoCameraDetected } from "../Icons/Modal/icon_no_camera_detected";
import { IconCameraDenied } from "../Icons/Modal/icon_camera_denied";

export interface iAppModalAction {
  text: string;
  onPress: VoidFunction;
}

export interface iAppModal {
  isOpen?: boolean;
  className?: string;
  title?: string;
  text?: string;
  onDismiss?: Function;
  dismissable?: boolean;
  action?: iAppModalAction;
  secondaryAction?: iAppModalAction;
  icon?: string;
  closeButton?: boolean;
}

export const AppModal = ({
  isOpen = false,
  title,
  text,
  onDismiss,
  dismissable = false,
  className = "",
  action,
  secondaryAction,
  icon,
}: iAppModal) => {
  const { t } = useTranslation();

  const icon_map: Record<string, JSX.Element> = {
    circle_x: <IconCircleX />,
    circle_key: <IconCircleKey />,
    no_camera: <IconNoCameraDetected />,
    denied_camera: <IconCameraDenied />,
  };

  function MultiLineText(props: { text: String }) {
    const text = props.text;
    return (
      <>
        {text.split("\n").map((s: string, i: number) => (
          <p key={i}>
            <Trans i18nKey={s} components={{ bold: <strong />, br: <br /> }} />
          </p>
        ))}
      </>
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      contentLabel="Error!"
      // Use class names instead, to make it easier to manage
      className={`modal w-[calc(100%-40px)] sm:max-w-[400px] sm:min-w-[400px] relative ${className}`}
    >
      {dismissable && (
        <div className="top-4 right-4 absolute cursor-pointer" onClick={() => onDismiss && onDismiss()}>
          <IconCircleX width={32} height={32} />
        </div>
      )}

      <div className="flex flex-col rounded-[20px] justify-center items-center">
        <div className="text-center my-5">{icon && icon in icon_map ? icon_map[icon] : <IconCircleExclamation />}</div>

        <div className="text-support-500 dark:text-support-100 text-2xl font-semibold my-4 text-center">{title ?? t("error_oops")}</div>

        <div className="text-center text-support-500 dark:text-support-100 text-md">
          <MultiLineText text={text ?? t("error_oops_description")} />
        </div>

        {(action || secondaryAction) && (
          <div className="mt-10">
            {action && (
              <Button
                text={action.text}
                type={ButtonType.Accent}
                containerClass="uppercase px-[32px] py-1"
                onClick={action.onPress}
                textStyles={{ fontSize: "14px" }}
              />
            )}

            {secondaryAction && (
              <Button
                text={secondaryAction.text ?? t("close")}
                type={ButtonType.Outline}
                textStyles={{ fontSize: "12px" }}
                containerClass={cx("uppercase mt-2")}
                onClick={() => secondaryAction.onPress()}
              />
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};
